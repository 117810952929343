import React from 'react';
import { replaceNewLinesWithBr } from '../../utils/sanity';
import { clsx } from '../../utils/utils';

import * as styles from './ImageCard.module.scss';

export interface ImageCardProps {
  imageUrl: string;
  title: string;
  text: string;
  className?: string;
}

const ImageCard = ({ imageUrl, title, text, className }: ImageCardProps): React.ReactElement => {
  return (
    <div className={clsx(className, styles.container)}>
      <div className={styles.imageContainer}>
        <img src={imageUrl} alt="" />
      </div>
      <div className={styles.textContainer}>
        <h3 className={styles.title}>{title}</h3>
        <p className={styles.text}>{replaceNewLinesWithBr(text)}</p>
      </div>
    </div>
  );
};

export default ImageCard;
