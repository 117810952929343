import React from 'react';
import { graphql } from 'gatsby';
import { clsx } from '../../utils/utils';
import { CommonModuleProps } from '../../templates/Page';

import * as styles from './CardGridModule.module.scss';

export const CardGridModuleFragment = graphql`
  fragment CardGridModule on SanityCardGridModule {
    title
    cards {
      icon {
        asset {
          url
        }
      }
      title
      text
    }
  }
`;

export type CardGridModuleProps = {
  title: string;
  cards: Array<{
    icon: {
      asset: {
        url: string;
      };
    };
    title: string;
    text: string;
  }>;
};

export function getModuleBgColor(props: CardGridModuleProps) {
  return 'white';
}

const CardGridModule = (props: CardGridModuleProps & CommonModuleProps): React.ReactElement => {
  const { title, cards, previousModuleBgColor } = props;

  return (
    <section
      className={clsx(
        styles.section,
        getModuleBgColor(props) === previousModuleBgColor && styles.sectionWithNoPadding,
      )}
    >
      <div className={clsx(styles.container)}>
        <h2 className={styles.title}>{title}</h2>
        <div className={styles.cardsContainer}>
          {cards.map((card, i) => (
            <div className={styles.card} key={i}>
              <div className={styles.iconContainer}>
                <img src={card.icon.asset.url} alt="" />
              </div>
              <span className={styles.cardTitle}>{card.title}</span>
              <p className={styles.cardText}>{card.text}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default CardGridModule;
