import React from 'react';
import { graphql } from 'gatsby';
import { clsx } from '../../utils/utils';
import { CommonModuleProps } from '../../templates/Page';
import { FaQuoteLeft } from 'react-icons/fa';

import * as styles from './TestimonialsModule.module.scss';
import { replaceNewLinesWithBr } from '../../utils/sanity';

export const TestimonialsModuleFragment = graphql`
  fragment TestimonialsModule on SanityTestimonialsModule {
    title
    testimonials {
      image {
        asset {
          url
        }
      }
      name
      position
      testimonial
    }
  }
`;

export type TestimonialsModuleProps = {
  title: string;
  testimonials: Array<{
    image: {
      asset: {
        url: string;
      };
    };
    name: string;
    position: string;
    testimonial: string;
  }>;
};

export function getModuleBgColor(props: TestimonialsModuleProps) {
  return 'white';
}

const TestimonialsModule = (
  props: TestimonialsModuleProps & CommonModuleProps,
): React.ReactElement => {
  const { title, testimonials, previousModuleBgColor } = props;

  return (
    <section
      className={clsx(
        styles.section,
        getModuleBgColor(props) === previousModuleBgColor && styles.sectionWithNoPadding,
      )}
    >
      <div className={clsx(styles.container)}>
        <h2 className={styles.title}>{title}</h2>
        {testimonials.map((testimonial, i) => (
          <div className={styles.testimonialWrapper} key={i}>
            <div className={styles.imageContainer}>
              <img src={testimonial.image.asset.url} alt="" />
            </div>
            <div className={styles.testimonialContainer}>
              <div className={styles.iconContainer}>
                <FaQuoteLeft className={styles.icon} />
              </div>
              <div className={styles.testimonialAndDividerContainer}>
                <p className={styles.testimonial}>
                  {replaceNewLinesWithBr(testimonial.testimonial)}
                </p>
                <div className={styles.divider}></div>
                <p className={styles.name}>
                  {testimonial.name}, {testimonial.position}
                </p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default TestimonialsModule;
