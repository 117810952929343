import React from 'react';
import { graphql } from 'gatsby';
import { clsx } from '../../utils/utils';
import { CommonModuleProps } from '../../templates/Page';
import ImageCard from './ImageCard';

import * as styles from './ImageCardGridModule.module.scss';

export const ImageCardGridModuleFragment = graphql`
  fragment ImageCardGridModule on SanityImageCardGridModule {
    title
    cards {
      image {
        asset {
          url
        }
      }
      title
      text
    }
  }
`;

interface Card {
  image: {
    asset: {
      url: string;
    };
  };
  title: string;
  text: string;
}

export type ImageCardGridModuleProps = {
  title: string;
  cards: Array<Card>;
};

export function getModuleBgColor(props: ImageCardGridModuleProps) {
  return 'white';
}

const ImageCardGridModule = (
  props: ImageCardGridModuleProps & CommonModuleProps,
): React.ReactElement => {
  const { title, cards, previousModuleBgColor } = props;

  return (
    <section
      className={clsx(
        styles.section,
        getModuleBgColor(props) === previousModuleBgColor && styles.sectionWithNoPadding,
      )}
    >
      <div className={clsx(styles.container)}>
        <h2 className={styles.title}>{title}</h2>
        <div className={styles.cardsContainer}>
          {cards.map((card, i) => (
            <ImageCard
              key={i}
              imageUrl={card.image.asset.url}
              text={card.text}
              title={card.title}
              className={styles.card}
            />
          ))}
        </div>
      </div>
    </section>
  );
};

export default ImageCardGridModule;
