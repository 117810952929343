// extracted by mini-css-extract-plugin
export var columnContainer = "TwoColumnTextModule-module--columnContainer--fe63f";
export var columnTitle = "TwoColumnTextModule-module--columnTitle--55843";
export var columnsContainer = "TwoColumnTextModule-module--columnsContainer--a6a55";
export var container = "TwoColumnTextModule-module--container--fec1a";
export var content = "TwoColumnTextModule-module--content--25a0e";
export var dividerContainer = "TwoColumnTextModule-module--dividerContainer--c0145";
export var dividerImageContainer = "TwoColumnTextModule-module--dividerImageContainer--5996a";
export var dividerLine = "TwoColumnTextModule-module--dividerLine--5ec05";
export var imageContainer = "TwoColumnTextModule-module--imageContainer--0c7ac";
export var introText = "TwoColumnTextModule-module--introText--c76ab";
export var outroText = "TwoColumnTextModule-module--outroText--6546d";
export var outroTextContainerContainer = "TwoColumnTextModule-module--outroTextContainerContainer--b32aa";
export var section = "TwoColumnTextModule-module--section--4c0ab";
export var sectionWithNoPadding = "TwoColumnTextModule-module--sectionWithNoPadding--f353a";
export var title = "TwoColumnTextModule-module--title--8ca3d";