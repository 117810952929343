import React, { useRef } from 'react';
import { graphql } from 'gatsby';
import '../fragments';
import Hero from '../components/ui/Hero';
import PageSEO from '../components/PageSEO';
import LayoutContainer from '../components/ui/LayoutContainer';
import logo from '../images/logo-final.svg';
// import '../styles/main.scss';

import { CommonModuleProps, QueryLandingPageHeroProps } from './Page';
import { LocalizedSEO } from '../fragments';
import CardGridModule, {
  CardGridModuleProps,
  getModuleBgColor as getCardGridModuleBgColor,
} from '../components/modules/CardGridModule';
import ImageCardGridModule, {
  ImageCardGridModuleProps,
  getModuleBgColor as getImageCardGridModuleBgColor,
} from '../components/modules/ImageCardGridModule';
import NumberedListModule, {
  NumberedListModuleProps,
  getModuleBgColor as getNumberedListModuleBgColor,
} from '../components/modules/NumberedListModule';
import TestimonialsModule, {
  TestimonialsModuleProps,
  getModuleBgColor as getTestimonialsModuleBgColor,
} from '../components/modules/TestimonialsModule';
import TwoColumnTextModule, {
  TwoColumnTextModuleProps,
  getModuleBgColor as getTwoColumnTextModuleBgColor,
} from '../components/modules/TwoColumnTextModule';
import CtaModule, {
  CtaModuleProps,
  getModuleBgColor as getCtaModuleBgColor,
} from '../components/modules/CtaModule';
import AccordionModule, {
  AccordionModuleProps,
  getModuleBgColor as getAccordionModuleBgColor,
} from '../components/modules/AccordionModule';
import { useDimensions, useScrollY } from '../utils/hooks';
import LandingPageHero from '../components/ui/LandingPageHero';
import * as styles from './LandingPage.module.scss';

export const query = graphql`
  query GetLandingPageById($id: String!) {
    sanityLandingPage(id: { eq: $id }) {
      slug {
        current
      }
      landingPageHero {
        title
        text
        videoText
        videoUrl
      }
      content {
        __typename
        ...CardGridModule
        ... on SanityCardGridModule {
          disabled
        }
        ...ImageCardGridModule
        ... on SanityImageCardGridModule {
          disabled
        }
        ...ImageCardGridModule
        ... on SanityImageCardGridModule {
          disabled
        }
        ...NumberedListModule
        ... on SanityNumberedListModule {
          disabled
        }
        ...NumberedListModule
        ... on SanityNumberedListModule {
          disabled
        }
        ...TestimonialsModule
        ... on SanityTestimonialsModule {
          disabled
        }
        ...TwoColumnTextModule
        ... on SanityTwoColumnTextModule {
          disabled
        }
        ...AccordionModule
        ... on SanityAccordionModule {
          disabled
        }
        ...CtaModule
        ... on SanityCtaModule {
          disabled
        }
      }
      seo {
        ...SEO
      }
    }
  }
`;

type LandingPageModule =
  | (CardGridModuleProps & { __typename: 'SanityCardGridModule' } & {
      disabled: boolean | null;
    })
  | (ImageCardGridModuleProps & { __typename: 'SanityImageCardGridModule' } & {
      disabled: boolean | null;
    })
  | (NumberedListModuleProps & { __typename: 'SanityNumberedListModule' } & {
      disabled: boolean | null;
    })
  | (TestimonialsModuleProps & { __typename: 'SanityTestimonialsModule' } & {
      disabled: boolean | null;
    })
  | (TwoColumnTextModuleProps & { __typename: 'SanityTwoColumnTextModule' } & {
      disabled: boolean | null;
    })
  | (AccordionModuleProps & { __typename: 'SanityAccordionModule' } & {
      disabled: boolean | null;
    })
  | (CtaModuleProps & { __typename: 'SanityCtaModule' } & {
      disabled: boolean | null;
    });

interface LandingPageProps {
  data: {
    sanityLandingPage: {
      slug: {
        current: string;
      };
      landingPageHero: QueryLandingPageHeroProps;
      content: Array<LandingPageModule>;
      seo?: LocalizedSEO;
    };
  };
}

const LandingPage = ({ data }: LandingPageProps): React.ReactElement => {
  const { slug, landingPageHero, content, seo } = data.sanityLandingPage;

  const enabledModules = content.filter(module => !module.disabled);
  const heroRef = useRef<HTMLElement | null>(null);
  const { height: heroHeight } = useDimensions(heroRef, { measureOnScroll: false }) || {};
  const scrollY = useScrollY();
  const headerVisible = !!scrollY && !!heroHeight && scrollY > heroHeight;

  return (
    <LayoutContainer currentPage={slug.current} isLandingPage isHeaderVisible={headerVisible}>
      <PageSEO
        defaultTitle={seo?.title || landingPageHero.title}
        defaultDescription={seo?.description}
        defaultImageUrl={seo?.image?.asset.url}
        pageSEO={seo}
      />
      <div className={styles.fakeHeader}>
        <div className={styles.logoContainer}>
          <img src={logo} alt="" />
        </div>
      </div>
      <LandingPageHero
        sectionRef={heroRef}
        title={landingPageHero.title}
        text={landingPageHero.text}
        videoText={landingPageHero.videoText}
        videoUrl={landingPageHero.videoUrl}
      ></LandingPageHero>

      {enabledModules.map((module, moduleIndex) => {
        const moduleKey = 'module-' + moduleIndex;

        // Defaults to white since we either have a hero (whose bottom part is white)
        // or we don't have a hero but have a title with a white background.
        let previousModuleBgColor = 'white';
        if (moduleIndex > 0) {
          const previousModule = enabledModules[moduleIndex - 1];
          if (previousModule.__typename === 'SanityCardGridModule') {
            previousModuleBgColor = getCardGridModuleBgColor(previousModule);
          } else if (previousModule.__typename === 'SanityImageCardGridModule') {
            previousModuleBgColor = getImageCardGridModuleBgColor(previousModule);
          } else if (previousModule.__typename === 'SanityNumberedListModule') {
            previousModuleBgColor = getNumberedListModuleBgColor(previousModule);
          } else if (previousModule.__typename === 'SanityTestimonialsModule') {
            previousModuleBgColor = getTestimonialsModuleBgColor(previousModule);
          } else if (previousModule.__typename === 'SanityTwoColumnTextModule') {
            previousModuleBgColor = getTwoColumnTextModuleBgColor(previousModule);
          } else if (previousModule.__typename === 'SanityAccordionModule') {
            previousModuleBgColor = getAccordionModuleBgColor(previousModule);
          } else if (previousModule.__typename === 'SanityCtaModule') {
            previousModuleBgColor = getCtaModuleBgColor(previousModule);
          } else {
            throw new Error(
              // @ts-ignore
              'Got unexpected previousModule with typename ' + previousModule.__typename,
            );
          }
        }

        const commonModuleProps: CommonModuleProps = {
          previousModuleBgColor,
        };

        if (module.__typename === 'SanityCardGridModule') {
          return (
            <CardGridModule key={moduleKey} {...module} {...commonModuleProps}></CardGridModule>
          );
        } else if (module.__typename === 'SanityImageCardGridModule') {
          return (
            <ImageCardGridModule
              key={moduleKey}
              {...module}
              {...commonModuleProps}
            ></ImageCardGridModule>
          );
        } else if (module.__typename === 'SanityNumberedListModule') {
          return (
            <NumberedListModule
              key={moduleKey}
              {...module}
              {...commonModuleProps}
            ></NumberedListModule>
          );
        } else if (module.__typename === 'SanityTestimonialsModule') {
          return (
            <TestimonialsModule
              key={moduleKey}
              {...module}
              {...commonModuleProps}
            ></TestimonialsModule>
          );
        } else if (module.__typename === 'SanityTwoColumnTextModule') {
          return (
            <TwoColumnTextModule
              key={moduleKey}
              {...module}
              {...commonModuleProps}
            ></TwoColumnTextModule>
          );
        } else if (module.__typename === 'SanityAccordionModule') {
          return (
            <AccordionModule key={moduleKey} {...module} {...commonModuleProps}></AccordionModule>
          );
        } else if (module.__typename === 'SanityCtaModule') {
          return <CtaModule key={moduleKey} {...module} {...commonModuleProps}></CtaModule>;
        } else {
          // @ts-ignore
          throw new Error('Got unexpected module with typename ' + module.__typename);
        }
      })}
    </LayoutContainer>
  );
};

export default LandingPage;
