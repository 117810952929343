import React from 'react';
import { graphql } from 'gatsby';
import BlockContent from '@sanity/block-content-to-react';
import { RawPortableText } from '../../types';
import serializers from '../../serializers';
import { clsx } from '../../utils/utils';
import { CommonModuleProps } from '../../templates/Page';
import divider from '../../images/divider.svg';
import * as styles from './TwoColumnTextModule.module.scss';
import { replaceNewLinesWithBr } from '../../utils/sanity';

export const TwoColumnTextModuleFragment = graphql`
  fragment TwoColumnTextModule on SanityTwoColumnTextModule {
    title
    introText
    columns {
      image {
        asset {
          url
        }
      }
      title
      _rawText(resolveReferences: { maxDepth: 6 })
    }
    outroText
  }
`;

export type TwoColumnTextModuleProps = {
  title: string;
  introText?: string;
  columns: Array<{
    image: {
      asset: {
        url: string;
      };
    };
    title: string;
    _rawText: RawPortableText;
  }>;
  outroText?: string;
};

export function getModuleBgColor(props: TwoColumnTextModuleProps) {
  return 'white';
}

const TwoColumnTextModule = (
  props: TwoColumnTextModuleProps & CommonModuleProps,
): React.ReactElement => {
  const { title, introText, columns, outroText, previousModuleBgColor } = props;

  return (
    <section
      className={clsx(
        styles.section,
        getModuleBgColor(props) === previousModuleBgColor && styles.sectionWithNoPadding,
      )}
    >
      <div className={clsx(styles.container)}>
        <h2 className={styles.title}>{title}</h2>
        {introText && <p className={styles.introText}>{introText}</p>}
        <div className={styles.columnsContainer}>
          {columns.map((column, i) => (
            <div className={styles.columnContainer} key={i}>
              <div className={styles.imageContainer}>
                <img src={column.image.asset.url} alt="" />
              </div>
              <h3 className={styles.columnTitle}>{column.title}</h3>
              <BlockContent
                className={styles.content}
                blocks={column._rawText}
                renderContainerOnSingleChild
                serializers={serializers}
              />
            </div>
          ))}
        </div>
        {outroText && (
          <div className={styles.outroTextContainerContainer}>
            <div className={styles.dividerContainer}>
              <div className={styles.dividerLine}></div>
              <div className={styles.dividerImageContainer}>
                <img src={divider} alt="" />
              </div>
              <div className={styles.dividerLine}></div>
            </div>

            <p className={styles.outroText}>{replaceNewLinesWithBr(outroText)}</p>
          </div>
        )}
      </div>
    </section>
  );
};

export default TwoColumnTextModule;
