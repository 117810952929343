// extracted by mini-css-extract-plugin
export var anchor = "NumberedListModule-module--anchor--0516e";
export var button = "NumberedListModule-module--button--ab6e1";
export var container = "NumberedListModule-module--container--0dcaf";
export var item = "NumberedListModule-module--item--452a0";
export var itemText = "NumberedListModule-module--itemText--dcc80";
export var itemTitle = "NumberedListModule-module--itemTitle--09050";
export var listContainer = "NumberedListModule-module--listContainer--42b0b";
export var number = "NumberedListModule-module--number--71f23";
export var numberContainer = "NumberedListModule-module--numberContainer--eaf51";
export var section = "NumberedListModule-module--section--482aa";
export var sectionWithNoPadding = "NumberedListModule-module--sectionWithNoPadding--69438";
export var title = "NumberedListModule-module--title--19e7a";