import React, { useState } from 'react';
import { replaceNewLinesWithBr } from '../../utils/sanity';
import { clsx, withDataLayer } from '../../utils/utils';
import usePortal from 'react-useportal';
import FsLightbox from 'fslightbox-react';
import getYouTubeID from 'get-youtube-id';
import HeroForm from './HeroForm';

import * as styles from './LandingPageHero.module.scss';
import '../../styles/main.scss';
import Video from './Video';

export type LandingPageHeroProps = {
  sectionRef: React.MutableRefObject<HTMLElement | null>;
  title: string;
  text: string;
  videoText?: string;
  videoUrl?: string;
};

const LandingPageHero = ({
  sectionRef,
  title,
  text,
  videoText,
  videoUrl,
}: LandingPageHeroProps): React.ReactElement => {
  const [lightboxToggler, setLightboxToggler] = useState(false);
  const { Portal } = usePortal();

  const id = videoUrl ? getYouTubeID(videoUrl) : '';
  const embedUrl = `https://www.youtube.com/embed/${id}`;

  return (
    <section ref={sectionRef} className={clsx(styles.heroSection)}>
      <div className={clsx(styles.container)}>
        <div className={clsx(styles.contentContainer, videoText && styles.withVideo)}>
          <h1 className={clsx(styles.title, title.length > 50 && styles.smallerTitle)}>{title}</h1>
          <p className={clsx(styles.paragraph)}>{replaceNewLinesWithBr(text)}</p>

          {videoUrl && (
            <>
              <Portal>
                <FsLightbox
                  toggler={lightboxToggler}
                  sources={[
                    // @ts-ignore
                    <iframe
                      key="video"
                      className={styles.iframe}
                      src={embedUrl + '?autoplay=1&enablejsapi=1'}
                      enablejsapi="true"
                    ></iframe>,
                  ]}
                ></FsLightbox>
              </Portal>

              <div className={styles.videoThumbnailContainer}>
                {videoText && (
                  <span className={styles.videoBubble}>{replaceNewLinesWithBr(videoText)}</span>
                )}
                <Video className={styles.video} url={videoUrl} textButton />
              </div>
            </>
          )}
        </div>
        <HeroForm className={styles.form} />
      </div>
    </section>
  );
};

export default LandingPageHero;
