import React, { useState } from 'react';
import { graphql } from 'gatsby';
import AnchorLink from 'react-anchor-link-smooth-scroll';

import { clsx, removeItem } from '../../utils/utils';
import { CommonModuleProps } from '../../templates/Page';
import Button from '../ui/Button';
import Accordion from '../ui/Accordion';

import * as styles from './AccordionModule.module.scss';

export const AccordionModuleFragment = graphql`
  fragment AccordionModule on SanityAccordionModule {
    title
    accordions {
      title
      text
    }
    buttonText
  }
`;

export type AccordionModuleProps = {
  title: string;
  accordions: Array<{
    title: string;
    text: string;
  }>;
  buttonText?: string;
};

export function getModuleBgColor(props: AccordionModuleProps) {
  return 'white';
}

const AccordionModule = (props: AccordionModuleProps & CommonModuleProps): React.ReactElement => {
  const [activeAccordions, setActiveAccordions] = useState<Array<number>>([]);

  const { title, accordions, buttonText, previousModuleBgColor } = props;

  return (
    <section
      className={clsx(
        styles.section,
        getModuleBgColor(props) === previousModuleBgColor && styles.sectionWithNoPadding,
      )}
    >
      <div className={clsx(styles.container)}>
        <h2 className={styles.title}>{title}</h2>
        <div className={styles.accordionsContainer}>
          {accordions.map((accordion, i) => (
            <Accordion
              key={i}
              title={accordion.title}
              isAccordionOpen={activeAccordions.includes(i)}
              onAccordionBoxTouch={() => {
                if (activeAccordions.includes(i)) {
                  setActiveAccordions(removeItem(activeAccordions, i));
                } else {
                  setActiveAccordions([...activeAccordions, i]);
                }
              }}
              theme={'white'}
              iconType={'plus'}
            >
              {accordion.text}
            </Accordion>
          ))}
        </div>
        {buttonText && (
          <AnchorLink href="#hero-form" className={styles.anchor}>
            <Button color="grapefruit" className={styles.button} rightIcon="arrow">
              {buttonText}
            </Button>
          </AnchorLink>
        )}
      </div>
    </section>
  );
};

export default AccordionModule;
