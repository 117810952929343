import React from 'react';
import { graphql } from 'gatsby';
import AnchorLink from 'react-anchor-link-smooth-scroll';

import { clsx } from '../../utils/utils';
import { CommonModuleProps } from '../../templates/Page';

import * as styles from './NumberedListModule.module.scss';
import Button from '../ui/Button';

export const NumberedListModuleFragment = graphql`
  fragment NumberedListModule on SanityNumberedListModule {
    title
    items {
      title
      text
    }
    buttonText
  }
`;

export type NumberedListModuleProps = {
  title: string;
  items: Array<{
    title: string;
    text: string;
  }>;
  buttonText?: string;
};

export function getModuleBgColor(props: NumberedListModuleProps) {
  return 'white';
}

const NumberedListModule = (
  props: NumberedListModuleProps & CommonModuleProps,
): React.ReactElement => {
  const { title, items, buttonText, previousModuleBgColor } = props;

  return (
    <section
      className={clsx(
        styles.section,
        getModuleBgColor(props) === previousModuleBgColor && styles.sectionWithNoPadding,
      )}
    >
      <div className={clsx(styles.container)}>
        <h2 className={styles.title}>{title}</h2>
        <div className={styles.listContainer}>
          {items.map((item, i) => (
            <div className={styles.item} key={i}>
              <div className={clsx(styles.numberContainer)}>
                <span className={styles.number}>{i + 1}</span>
              </div>
              <div className={styles.textContainer}>
                <h3 className={clsx(styles.itemTitle)}>{item.title}</h3>
                <p className={styles.itemText}>{item.text}</p>
              </div>
            </div>
          ))}
        </div>
        {buttonText && (
          <AnchorLink href="#hero-form" className={styles.anchor}>
            <Button color="grapefruit" className={styles.button} rightIcon="arrow">
              {buttonText}
            </Button>
          </AnchorLink>
        )}
      </div>
    </section>
  );
};

export default NumberedListModule;
