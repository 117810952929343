import React from 'react';
import { graphql } from 'gatsby';
import { clsx } from '../../utils/utils';
import { CommonModuleProps } from '../../templates/Page';
import logomark from '../../images/GVCLogomark.svg';
import * as styles from './CtaModule.module.scss';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import Button from '../ui/Button';

export const CtaModuleFragment = graphql`
  fragment CtaModule on SanityCtaModule {
    title
    buttonText
  }
`;

export type CtaModuleProps = {
  title: string;
  buttonText: string;
};

export function getModuleBgColor(props: CtaModuleProps) {
  return 'white';
}

const CtaModule = (props: CtaModuleProps & CommonModuleProps): React.ReactElement => {
  const { title, buttonText, previousModuleBgColor } = props;

  return (
    <section
      className={clsx(
        styles.section,
        getModuleBgColor(props) === previousModuleBgColor && styles.sectionWithNoPadding,
      )}
    >
      <div className={clsx(styles.container)}>
        <div className={styles.textAndButton}>
          <h2 className={styles.title}>{title}</h2>
          <AnchorLink href="#hero-form" className={styles.anchor}>
            <Button className={styles.button} rightIcon="arrow">
              {buttonText}
            </Button>
          </AnchorLink>
        </div>
        <div className={styles.imageContainer}>
          <img src={logomark} alt="" />
        </div>
      </div>
    </section>
  );
};

export default CtaModule;
