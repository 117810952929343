// extracted by mini-css-extract-plugin
export var container = "TestimonialsModule-module--container--e6933";
export var divider = "TestimonialsModule-module--divider--562c5";
export var icon = "TestimonialsModule-module--icon--cefa6";
export var iconContainer = "TestimonialsModule-module--iconContainer--451a6";
export var imageContainer = "TestimonialsModule-module--imageContainer--a7626";
export var name = "TestimonialsModule-module--name--99894";
export var section = "TestimonialsModule-module--section--5f39f";
export var sectionWithNoPadding = "TestimonialsModule-module--sectionWithNoPadding--b5976";
export var testimonial = "TestimonialsModule-module--testimonial--7dcda";
export var testimonialAndDividerContainer = "TestimonialsModule-module--testimonialAndDividerContainer--2e495";
export var testimonialContainer = "TestimonialsModule-module--testimonialContainer--ccffe";
export var testimonialWrapper = "TestimonialsModule-module--testimonialWrapper--c2135";
export var title = "TestimonialsModule-module--title--377c6";